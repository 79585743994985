import React from "react";
import SEO from "../components/seo";
import Layout from "../components/layout";
import {graphql} from "gatsby";
import Articles from "../components/articles"
import Banner from "../components/banner";

const AuthorPage = ({ data, pageContext }) => {
  return (
    <Layout>
      <SEO title={pageContext.author} keywords={[`働き方`, `リモートデスクトップ`, `時短`]} />
      <Articles title={pageContext.author+'一覧'} data={data.articles}/>
      <Banner/>
    </Layout>
  );
}

export default AuthorPage;

export const query = graphql`
query($author: String!) {
    articles: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC },
      filter: {
       fields: {
        reserved: { eq: false }
        author: { eq: $author }
      }}) {
      totalCount
      edges {
        node {
          id
          fields {
            date(formatString: "YYYY年M月D日")
            author
            thumbnail {
              childImageSharp {
                fixed(width: 304, height: 240, cropFocus: CENTER, quality: 90) {
                  width
                  height
                  src
                  srcSet
                }
              }
            }
            title
            slug
            attributes
            summary
            tags
          }
          excerpt
        }
      }
    }  
  } 
`
